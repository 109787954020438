<template>
  <div class="my-5">
    <div v-if="!mainLoading && result">
      <v-row dense class="mb-5">
        <v-col cols="12" lg="auto">
          <v-avatar color="grey lighten-3" size="150" class="rounded-pill">
            <atoms-image
              v-if="result.client && result.client.logo"
              :width="100"
              lazy-src="/lazy-img.webp"
              :src="result.client.logo"
            />
            <v-icon v-else light>mdi-domain</v-icon>
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          lg="auto"
          class="grow d-flex flex-column flex-lg-row justify-space-between align-center pa-0 pl-2"
        >
          <div :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto' }">
            <atoms-title>{{ result.title ? $toCapitalize(result.title) : 'Tidak dicantumkan' }}</atoms-title>
            <atoms-text>{{ (result.client && 'Klien ' + result.client.name) || '' }}</atoms-text>

            <atoms-text>{{ (result.workerIds && result.workerIds.length) || 0 }} Pelamar</atoms-text>
          </div>
          <div
            :class="['d-flex flex-column', ($vuetify.breakpoint.smAndDown && 'mt-5') || '']"
            :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto' }"
          >
            <atoms-button to="/vacancy" class="red white--text mt-2 mt-lg-0">Kembali</atoms-button>
          </div>
        </v-col>
      </v-row>
      <v-tabs
        background-color="accent"
        color="primary"
        height="40"
        v-model="tabs"
        hide-slider
        :show-arrows="$vuetify.breakpoint.smAndDown ? true : false"
        :center-active="$vuetify.breakpoint.smAndDown ? true : false"
        class="rounded-lg d-flex justify-start align-center px-0 my-5"
      >
        <v-tab
          v-for="i in ['Konten', 'Pelamar']"
          :key="i"
          class="text-capitalize"
          active-class="primary lighten-5 primary--text rounded-lg ma-1"
        >
          <atoms-text class="font-weight-medium">{{ $toCapitalize(i) }}</atoms-text>
        </v-tab>
      </v-tabs>
      <div class="">
        <v-fade-transition>
          <div v-if="tabs === 0" v-html="result.content" class="accent rounded-lg pa-5"></div>
        </v-fade-transition>
        <v-fade-transition>
          <div v-if="tabs === 1" class="px-0">
            <div v-if="workers && workers.length > 0">
              <div class="d-flex">
                <atoms-button
                  style="width: 100%"
                  class="teal lighten-5 teal--text text--darken-2 my-3"
                  @click.prevent="exportXlsx"
                  ><v-icon left>mdi-google-spreadsheet</v-icon>Export XLSX
                  <span v-if="loadingXlsxPercentage">{{ loadingXlsxPercentage }}%</span></atoms-button
                >
              </div>
              <div
                v-for="(worker, i) in workers || []"
                :key="i"
                :class="['accent', 'rounded-lg pa-4 mb-2']"
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
              >
                <v-row align="start">
                  <v-col cols="12" lg="auto" class="shrink">
                    <v-avatar>
                      <atoms-image v-if="worker && worker.photo" :enableZoom="true" :src="worker.photo" />
                      <v-icon v-else>mdi-account</v-icon></v-avatar
                    >
                  </v-col>
                  <v-col cols="12" lg="3" align="start">
                    <atoms-text class="font-weight-bold">{{ worker.fullName }}</atoms-text>

                    <atoms-text class="primary--text"
                      >{{ (worker.address && worker.address.city) || '' }}
                      -
                      {{ (worker.address && worker.address.province) || '' }}</atoms-text
                    >
                  </v-col>
                  <v-col align="start" cols="12" lg="auto">
                    <atoms-text class="black--text font-weight-medium"> Tanggal Melamar</atoms-text>
                    <atoms-text>
                      {{ worker.appliedDate }}
                    </atoms-text>
                  </v-col>
                  <v-col align="start" cols="12" lg="auto">
                    <atoms-text class="black--text font-weight-medium"> Kontak</atoms-text>
                    <atoms-text v-if="worker.email" :href="`mailto:${worker.email}`" class="primary--text">
                      {{ worker.email }}</atoms-text
                    >
                    <atoms-text v-else class="secondary--text">-</atoms-text>
                    <atoms-text>
                      {{
                        getContacted(worker._id) && getContacted(worker._id).status
                          ? getContacted(worker._id).status
                          : getContacted(worker._id)
                          ? 'Contacted'
                          : 'Not Contacted'
                      }}</atoms-text
                    >
                    <!-- <atoms-text class="primary--text"
                            >Telp : {{ worker.phone || "-" }}</atoms-text
                          > -->
                  </v-col>
                  <v-col align="start" cols="12" lg="auto">
                    <atoms-text class="black--text font-weight-medium"> Keterampilan</atoms-text>
                    <atoms-text>
                      <ul>
                        <li v-for="(skill, i) in worker.skills" :key="i">
                          {{ skill.name }}
                        </li>
                      </ul>
                    </atoms-text>
                  </v-col>
                  <v-col align="start" cols="12" lg="auto">
                    <atoms-text class="black--text font-weight-medium"> Pengalaman Kerja</atoms-text>
                    <ul>
                      <li v-for="(exp, i) in worker.experiences" :key="i">
                        {{ exp.experience }}
                      </li>
                    </ul>
                  </v-col>
                  <v-col align="start" cols="12" lg="auto">
                    <atoms-text class="black--text font-weight-medium"> Status</atoms-text>
                    <atoms-text
                      :title="
                        worker.status == 'anggota'
                          ? 'Pekerja Sudah Pernah Bekerja dengan Hayokerja'
                          : 'Pekerja Belum Pernah Bekerja dengan Hayokerja'
                      "
                    >
                      {{ worker.status }}
                    </atoms-text>
                  </v-col>
                  <v-col cols="12" lg="auto" class="grow" align="end">
                    <!-- :disabled="!$store.state.client.approved" -->
                    <atoms-button
                      :full="$vuetify.breakpoint.smAndDown"
                      @click="
                        () => {
                          $router.push({
                            path: '/lowongan/pelamar',
                            query: {
                              targetWorker: worker._id,
                              targetVacancy: result._id,
                            },
                          })
                        }
                      "
                      class="primary"
                      >Detail</atoms-button
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
            <molecules-container v-else class="d-flex justify-center align-center py-16 accent rounded-lg">
              <div class="text-center">
                <atoms-image :width="250" src="/done.svg" class="mx-auto" />
                <atoms-title :h3="true" class="mt-6">Tidak ada pelamar yang ditemukan!</atoms-title>
              </div>
            </molecules-container>
          </div>
        </v-fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: null,
      result: null,
      workers: [],
      mainLoading: true,
      loadingXlsxPercentage: 0,
    }
  },
  async mounted() {
    if (!this.$route.params._id) {
      this.$router.push('/lowongan')
    } else {
      await this.getVacancy()
    }
  },
  methods: {
    getContacted(workerId) {
      let payload = this.result?.contacted
      payload = payload?.find((it) => it._id === workerId)
      if (payload?._id) {
        return payload
      }
      return null
    },
    async getVacancy(payload) {
      this.mainLoading = true
      try {
        const resp = await this.$store.dispatch('vacancy/getAll', {
          custom: {
            _id: this.$route.params._id,
            ...payload,
          },
          page: 1,
          limit: 1,
          returnOnly: true,
        })
        if (resp?.success) {
          this.result = resp?.result?.[0]
          this.workers = await Promise.all(
            this.result?.workers?.map(async (worker) => {
              const detail = await this.$api
                .get('WorkerDetails/get', {
                  params: {
                    jsonQuery: JSON.stringify({ workerId: worker._id }),
                  },
                })
                .then((res) => res?.result?.[0] || {})
              console.log(detail)
              const isAttendanceExist = await this.$api
                .get('Absensi/count', {
                  params: {
                    jsonQuery: JSON.stringify({ ktpPhl: worker._id, supervisorApproval: 'Approved' }),
                  },
                })
                .then((res) => res.result > 0)
              return {
                ...worker,
                appliedDate: this.result?.appliedDate?.[worker._id]
                  ? this.$moment(this.result.appliedDate[worker._id]).format('DD MMMM YYYY, H:mm:ss')
                  : '-',
                skills: detail.skills,
                experiences: detail.experiences,
                status: isAttendanceExist ? 'anggota' : 'hayokerja',
              }
            }) || [],
          )
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },

    async exportXlsx() {
      const isConfirmed = confirm('Yakin ekspor dengan filter saat ini?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      try {
        const filter = this.getFilter()
        const jsonQuery = {
          jsonQuery: JSON.stringify(filter),
        }
        const query = this.$queryString.stringify(jsonQuery)
        await this.$api
          .get(`Invoices/exportXlsx/?${query}`, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              const total = parseFloat(
                progressEvent?.currentTarget?.responseHeaders?.['Content-Length'] || progressEvent.total,
              )
              const current = progressEvent?.currentTarget?.response?.length || progressEvent.loaded
              const percentCompleted = Math.floor((current / total) * 100)
              this.loadingXlsxPercentage = percentCompleted
            },
          })
          .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Invoices.xlsx`)
            document.body.appendChild(link)
            link.click()
            this.loadingXlsxPercentage = 0
          })
      } catch (err) {
        if (err?.message === 'Request failed with status code 500') {
          this.$store.commit('SET', { loading: false })
          alert('Penarikan data gagal, coba untuk perkecil range filter-nya')
        }
        alert(err?.response?.data?.message || err?.message)
      }
      this.$store.commit('SET', { loading: false })
    },
    async exportXlsx() {
      const isConfirmed = confirm('Data peminat akan digenerate dalam bentuk file berformat xlsx)')
      if (!isConfirmed) return

      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      if (!this.workers || this.workers?.length < 1) {
        alert('Kesalahan data saat mengenerate')
        return
      }

      try {
        const jsonQuery = {
          _id: { $in: this.workers?.map((it) => it._id) },
        }
        await this.$api
          .get(`Workers/exportXlsx`, {
            params: {
              jsonQuery,
            },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              const total = parseFloat(
                progressEvent?.currentTarget?.responseHeaders?.['Content-Length'] || progressEvent.total,
              )
              const current = progressEvent?.currentTarget?.response?.length || progressEvent.loaded
              const percentCompleted = Math.floor((current / total) * 100)
              this.loadingXlsxPercentage = percentCompleted
            },
          })
          .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Workers Apply.xlsx`)
            document.body.appendChild(link)
            link.click()
            this.loadingXlsxPercentage = 0
          })
      } catch (err) {
        if (err?.message === 'Request failed with status code 500') {
          this.$store.commit('SET', { loading: false })
          alert('Penarikan data gagal, coba lagi nanti')
        }
        alert(err?.response?.data?.message || err?.message)
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
